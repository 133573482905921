<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="orders" :actions="actions" />
        </div>
      </div>
    </div>

    <el-dialog
      title="Export"
      :visible.sync="dialogExportVisible"
      width="50%"
    >
      <div class="block text-center">
        <el-date-picker
          size='large'
          v-model="dateRange"
          type="daterange"
          start-placeholder="Từ ngày"
          end-placeholder="Đến ngày">
        </el-date-picker>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="sm" @click="dialogExportVisible = false">Đóng</el-button>
        <el-button size="sm" type="primary" @click="exportData">Export</el-button>
      </span>
    </el-dialog>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
  import { MessageBox, Message, Dialog, Button, DatePicker } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import orderSchemas from './order-schemas'
  import dtHelper from 'src/helpers/datatable'
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default{
    components: {
      MyTable,
      MyFilter,
      Loading,
      ElButton: Button,
      ElDialog: Dialog,
      ElDatePicker: DatePicker
    },
    computed: {
      orders() {
        let rows = this.$store.state.orderVips;
        return dtHelper.filterByRules(rows, this.filterOutput);
      },
    },
    data() {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(orderSchemas),
        filter: {},
        rules: dtHelper.buildRules(orderSchemas),
        dialogExportVisible: false,
        dateRange: undefined,
        isLoading: false,
        actions: [{
          type: 'primary',
          icon: 'nc-icon nc-ruler-pencil',
          callback: this.edit
        }]
      }
    },

    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchOrderVips');
      this.$store.dispatch('setPageTitle', 'Quản lý đơn hàng');
      this.$store.dispatch('setCurrentActions', [{
        label: 'Export',
        type: 'primary',
        icon: '',
        callback: this.showModalExport
      }])
    },

    methods: {

      edit(index, row) {
        this.$router.push('/order-vips/' + row.id);
      },

      exportData() {
        if (!this.dateRange) {
          return Message({
            type: 'error',
            message: 'Chưa chọn khoảng thời gian'
          });
        }

        let from_date = this.dateRange[0];
        let to_date = this.dateRange[1];
        let data = {
          from: new Date(from_date).getTime() / 1000,
          to: new Date(to_date).getTime() / 1000
        };

        this.isLoading = true;
        this.$store.dispatch('exportOrderVip', data)
        .then(res => {
          if (res.success) {
            location.href = res.url;
            setTimeout(_ => {
              this.isLoading = false;
          }, 500)
          } else {
            Message({type: 'error', message: res.message});
          }
        })
      },

      showModalExport() {
        this.dialogExportVisible = true;
      },

      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      }
    },

    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
