var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-md-12 card p-0" }, [
        _c("div", { staticClass: "card-body row p-0" }, [
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("my-filter", {
                attrs: { rules: _vm.rules },
                on: { "filter-change": _vm.updateFilter },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-sm-12" },
            [
              _c("my-table", {
                attrs: {
                  columnDefs: _vm.columnDefs,
                  "data-rows": _vm.orders,
                  actions: _vm.actions,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Export",
            visible: _vm.dialogExportVisible,
            width: "50%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogExportVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "block text-center" },
            [
              _c("el-date-picker", {
                attrs: {
                  size: "large",
                  type: "daterange",
                  "start-placeholder": "Từ ngày",
                  "end-placeholder": "Đến ngày",
                },
                model: {
                  value: _vm.dateRange,
                  callback: function ($$v) {
                    _vm.dateRange = $$v
                  },
                  expression: "dateRange",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "sm" },
                  on: {
                    click: function ($event) {
                      _vm.dialogExportVisible = false
                    },
                  },
                },
                [_vm._v("Đóng")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "sm", type: "primary" },
                  on: { click: _vm.exportData },
                },
                [_vm._v("Export")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("loading", {
        attrs: { active: _vm.isLoading },
        on: {
          "update:active": function ($event) {
            _vm.isLoading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }