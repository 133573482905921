export default [
  {
    prop: 'id',
    label: 'ID',
    type: 'id',
    minWidth: 100
  },
  {
    prop: 'user_id',
    label: 'User ID',
    type: 'id',
    minWidth: 100,
    external: true,
    link: 'dashboard.irace.vn/#/users'
  },
  {
    prop: 'name',
    label: 'Họ tên',
    type: 'text',
    minWidth: 180
  },
  {
    prop: 'phone',
    label: 'SĐT',
    type: 'text',
    minWidth: 120
  },
  {
    prop: 'email',
    label: 'Email',
    type: 'text',
    minWidth: 160
  },
  {
    prop: 'created_at',
    label: 'Thời gian',
    type: 'datetime',
    minWidth: 160
  },
  {
    prop: 'status',
    label: 'Trạng thái',
    type: 'select',
    minWidth: 160,
    options: [{
      value: 0,
      title: 'Chưa thanh toán'
    },
    {
      value: 1,
      title: 'Đã thanh toán'
    }]
  },
  {
    prop: 'total_payment',
    label: 'Số tiền',
    minWidth: 120,
    type: 'number'
  }
]